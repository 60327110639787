body{
  background: #f9f9f9;
}

*{
  margin:0;
  padding:0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}
@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

body.swal2-toast-shown .swal2-container{
  z-index: 100000!important;
}

.custom-file-upload {
  border-radius: 7px;
  display: inline-block;
  padding: 6px 12px;
  border: 1px solid rgb(2 119 189);
  color: rgb(2 119 189);
  cursor: pointer;
  text-align: center;
  padding: 33px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.responsive {
  width: 100%;
  height: auto;
}

.calendar table td, .calendar table th{
  font-family: sans-serif !important;
}

.active {
  color: #005282 !important;
  background: #e8e8e8 !important;
}

.title-supervisor{
  border: 2px solid #d5d5d5;
  background-color: #eaeaea;

}
.supervisor{
  text-align: center;
  margin-bottom: 69px;
}
.supervisor .title-supervisor .subtitle-supervisor{
  color: #005282;
}
.supervisor .title-supervisor p{
  color: #525252;
  font-weight: 800;
  font-size: 21px;
  display: block;
}
.hierarchy-supervisor{
  width: 100%;
  border:1px solid #d5d5d5;
  border-bottom: 3px solid #ced7e9;
}
.hierarchy-supervisor .subtitle-adviser{
  color: #bf8888;
  font-size: 15px;
}
.hierarchy-supervisor .title-adviser{
  color: #2a3336;
  font-size: 17px;
}

.arrow-supervisor{
  height: 3px;
  background:#ced7e9;
}

.supervisor-content {
  width: 50%;
  margin: auto;
  /* display: flex; */
  /* align-items: center; */
}


.line-supervisor .line {
  height: 42px;
  width: 3px;
  margin: auto;
  background: #d5d5d5;
}

.inactive{
  color: #d31e36;
  font-weight: 700;
  padding: 0 5px;
  border: 1px solid #d31e36;
  border-radius: 8px;
}


.inactive-item{
  border-left:5px red solid;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-1t29gy6-MuiToolbar-root{
    box-shadow: -1px 1px 11px 4px rgba(209,209,209,0.75) !important;
    -webkit-box-shadow: -1px 1px 11px 4px rgba(209,209,209,0.75) !important;
    -moz-box-shadow: -1px 1px 11px 4px rgba(209,209,209,0.75) !important;
}

